@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "Calibri";
  font-style: normal;
  font-weight: normal;
  src: local("Calibri"), url("./assets/fonts/Calibri.ttf") format("truetype");
}
@font-face {
  font-family: "Calibri";
  font-style: normal;
  font-weight: normal;
  src: local("Calibri"),
    url("./assets/fonts/CalibriBold.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Calibri";
}

.header-wrap,
.header-wrap * {
  -webkit-transition: 0.2s linear all;
  -moz-transition: 0.2s linear all;
  -o-transition: 0.2s linear all;
  transition: 0.2s linear all;
}
.header-wrap {
  position: fixed;
  background-color: #fff;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 20;
}
.header-wrap.scrolled > .orange {
  height: 0;
}
.header-wrap.scrolled .navbar {
  margin: 0 !important;
}
.content {
  margin-top: 166px;
}

.nav-item {
  margin: 0 5px;
}
nav .nav-link {
  font-size: 18px;
  padding-left: 18px;
  padding-right: 18px;
  font-weight: 600;
  cursor: pointer;
}
.navbar-brand img {
  height: 50px;
}
p {
  font-size: 18px;
  color: #4b4b4b;
  font-family: "Calibri";
}
.bgimg1 {
  position: relative;
  background-color: #e5e5e5;
  height: 650px;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}
.bgimg1::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -55px;
  width: 0;
  height: 0;
  border-left: 55px solid transparent;
  border-right: 55px solid transparent;
  border-top: 45px solid #fff;
}
.bgimg1::before {
  content: "";
  position: absolute;
  bottom: -45px;
  left: 50%;
  margin-left: -55px;
  width: 0;
  height: 0;
  border-left: 55px solid transparent;
  border-right: 55px solid transparent;
  border-top: 45px solid #e5e5e5;
}

.bg-secondary {
  background-color: #b1bcc5 !important;
}
.orange {
  background-color: #e84821;
}
.h-top {
  overflow: hidden;
}
.orange span {
  color: #fff;
}
.nav-link {
  padding: 3px 15px !important;
  color: black;
}
.nav-item .nav-link.active,
.nav-item .nav-link:hover {
  color: #fff !important;
  background-color: #e84821 !important;
  border-color: #e84821 !important;
  border-radius: 10px !important;
  padding: 3px 15px !important;
}
.orange-text {
  color: #e84821;
}
.fs-40 {
  font-size: 40px;
}
.button {
  color: #fff !important;
  background-color: #e84821 !important;
  border-color: #e84821 !important;
  border-radius: 10px !important;
  padding: 10px 30px !important;
  background: linear-gradient(-15deg, #e7482c 50%, #eb7e64 50%);
  font-size: 18px;
  font-weight: 500;
}
.button.btn-dark {
  background-color: #6c757d !important;
  border-color: #6c757d !important;
  background: linear-gradient(-15deg, #454545 50%, #6c757d 50%);
}
.bttns {
  color: #e84821 !important;
  background-color: white !important;
  border-color: white !important;
  border-radius: 10px !important;
  padding: 5px 30px !important;
}
.btns-top {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bgimg3 {
  padding-top: 50px;
  padding-bottom: 30px;
}
.footer {
  background: #434343;
  padding: 20px;
}
.bg-img-2 {
  position: relative;
  background: #434343;
  padding: 100px 50px;
}
.bg-img-2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -55px;
  width: 0;
  height: 0;
  border-left: 55px solid transparent;
  border-right: 55px solid transparent;
  border-top: 45px solid #FFFFFF;
}
.bg-img-2::before {
  content: "";
  position: absolute;
  bottom: -45px;
  left: 50%;
  margin-left: -55px;
  width: 0;
  height: 0;
  border-left: 55px solid transparent;
  border-right: 55px solid transparent;
  border-top: 45px solid #434343;
}

.bg-img-2 .card-img-top {min-height: 495px; width: 100%; object-fit: cover;}
.bg-img-2 h2 {min-height: 38.5px; overflow: hidden;}
.bg-img-2 p {min-height: 162px; overflow: hidden;}


.btn-orange {
  color: white !important;
  background-color: #e84821 !important;
  border-color: #e84821 !important;
  border-radius: 10px !important;
  width: 150px;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
}
.btn.btn-bordered {
	background: none !important;
}
.card {
  margin-right: 6px !important ;
  margin-left: 6px !important;
  border: 0 !important;
  border-radius: 0 !important;
}
.card-img-top {
  border-radius: 0 !important;
}
.warning {
  background-color: #ec8d22 !important;
}
.card-body {
  position: relative;
}
.rectangle {
  position: absolute;
  top: -50px;
  left: 0px;
  width: 100%;
}
.rectangle img {
  height: 52px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  height: 40px;
  width: 40px;
  background-color: #e84821;
  background-size: 20px;
}

.sec-caseDetail {padding-bottom: 50px;}
.sec-caseDetail img {display: block; max-width: 100%; margin-bottom: 10px;}
.sec-caseDetail .fa-check {margin-right: 10px;}
.sec-caseDetail .titleImg {display: inline-block; margin: 0;}

.page-inner {padding: 30px 0 50px;}
.page-inner img {display: block; max-width: 100%;}
.page-inner .card {
  margin: 0 0 35px 0 !important;
  box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.5);
  border-radius: 10px !important;
}
.post-meta {margin-bottom: 10px;}
.post-meta > span {margin: 0 8px; font-weight: 700; color: #626262;}
.post-meta > span:first-child {margin-left: 0;}
.page-blog h3 {font-weight: 700; color: #e84821;}
.button.button-small {padding: 5px 20px !important;}
.pageTitle {text-align: center; color: #626262; font-weight: 700; margin: 0 0 30px 0;}



.page-link, .page-link:hover {color: #e84821;}
.page-item.active .page-link {
	background-color: #e84821;
	border-color: #e84821;
}
.page-link:focus {box-shadow: none;}
.pagination {justify-content: flex-end;}

.pageDetailTitle {color: #e84821; font-weight: 700; text-align: center; margin-bottom: 30px;}
.pageDetailSubTitle {
	color: #7b7b7b;
	font-style: italic;
	margin-bottom: 15px;
	border-left: 5px solid #e84821;
	padding: 10px 10px 10px 12px;
}
.tags span {margin-right: 15px; font-style: italic;}
.tags i {color: #e84821;font-size: 12px;margin-right: 7px;}
.page-blog-detail .post-meta {margin-bottom: 30px;}
.page-blog h3 a {color: inherit !important; text-decoration: none !important;}
.page-blog-list .detail {
	max-height: 85px;
	overflow: hidden;
	margin-bottom: 15px;
}
.page-blog-detail .detail img {float: left; max-width: 350px; margin: 0 20px 15px 0;}
.page-blog-detail .detail h5 {font-weight: 700; margin-bottom: 15px;}

/* Login form */

.form-user-wrap {background-color: #b5becc;height: 100vh;overflow-y: auto; background-repeat: no-repeat; background-size: cover;
  background-position: center center; position: relative;}
.form-user-wrap > .container {position: relative; z-index: 1;}
.form-user-wrap::after {content: ''; position: absolute; left: 0; top: 0; right: 0; bottom: 0; background-color: rgba(0, 0, 0, 0.5);}
.card-user {box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);padding: 30px;}
.form-user h2 {color: #636363;margin: 0 0 15px;position: relative;text-align: center;}
.form-user h2::before {left: 0;}
.form-user h2::after {right: 0;}
.form-user h2::before,
.form-user h2::after {content: '';height: 2px;width: 30%;background: #d4d4d4;position: absolute;top: 50%;z-index: 2;}
.form-user .hint-text {color: #999;margin-bottom: 30px;text-align: center;}
.view-more .btn {margin-right: 15px;}
.btn-icon {
	display: inline-block;
	padding: 6px 12px;
	color: #e84821 !important;
	vertical-align: middle;
	margin-right: 15px;
	box-shadow: 0 0 8px -3px rgba(0, 0, 0, 0.8);
	border-radius: 10px;
	background-color: #FFF;
  cursor: pointer;
}

.angular-editor-toolbar-set:nth-child(7),
.angular-editor-toolbar-set:nth-child(11) {
  display: none !important;
}
.forAdd .blogId {
  opacity: 0;
  height: 1px;
  overflow: hidden;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}
.forAdd .full-in-new {
  max-width: 100%;
  flex-grow: 1;
}

.card.card-content {
	margin: 0 !important;
	/* border: 1px solid #F0F0F0 !important; */
	box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.4);
	padding: 15px;
	border-radius: 7px !important;
	overflow: hidden;
}

.carousel-caption .desc p {
  color: #FFF;
  font-size: 24px;
}


@media (max-width: 1500px){

  .bg-img-2 .card-img-top {min-height: 250px;}

}

@media (max-width: 1400px){

  .carousel-caption .desc p {
    font-size: 18px;
  }

}

@media (max-width: 1280px) {
  .app-header nav .nav-link {font-size: 14px;}
}

@media (max-width: 991px) {
  .content {
    margin-top: 130px;
  }
  .sec-caseDetail {padding-top: 25px;}
  .carousel-caption h1 br {display: none;}
  .app-header nav .nav-link {font-size: 16px;}
  .app-header .navbar-collapse {margin-top: 15px;}
}

@media (max-width: 768px) {
  .bg-img-2 {
    padding: 80px 20px;
  }
  .bgimg1 {
    background: #e5e5e5;
    padding: 0;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .bgimg3 {
    display: flex;
    padding: 0;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }
  .navbar-brand img {
    height: 30px;
  }
}

@media (min-width: 2000px) {
  .bgimg1 {
    padding-top: 0;
  }
  .bgimg1 .row,
  .bgimg3 .row {
    align-items: center !important;
  }
  .container {
    max-width: 1400px;
  }
}

@media (max-width: 767px) {
  .content {
    margin-top: 98px;
  }
  .bgimg3,
  .bgimg1 {
    height: auto;
    padding-bottom: 30px;
  }
  .bgimg1 {
    padding-top: 30px;
  }
  .bgimg1::after,
  .bg-img-2::after {
    margin-left: -30px;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 25px solid #e5e5e5;
  }
  .bgimg1::before,
  .bg-img-2::before {
    bottom: -25px;
    margin-left: -30px;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 25px solid #e5e5e5;
  }
  .bg-img-2::before {
    border-top-color: #434343;
  }
  .bg-img-2 {
    padding: 70px 15px 20px;
  }
  .social-icons {
    margin-bottom: 30px;
  }
  .rectangle {
    top: -25px;
  }
  .rectangle img {
    height: 25px;
  }
  .carousel-indicators {
    margin-bottom: 5px;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 30px;
    width: 30px;
    background-size: 10px;
  }
  .fs-40 {
    font-size: 28px;
  }
  .blog-img {margin-bottom: 15px;}
  .carousel-caption .desc p {
    font-size: 14px;
  }
}
